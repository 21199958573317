import PrivateRoute from "../Components/PrivateRoute";
import KanbanBoard from "../Components/Kanban/KanbanBoard";
import Layout from "../Components/Layout/";
import React from "react";

const Kanban = () => {
  return (
    <PrivateRoute>
        <Layout >
            <KanbanBoard />
        </Layout>
    </PrivateRoute>
  );
}

export default Kanban;
