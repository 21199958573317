import {Avatar, Box, Icon, Menu, MenuButton, MenuItemOption, MenuList} from "@chakra-ui/react";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../Hooks/useAuth";
import ComposeEmailDrawer from "./Email/ComposeEmailDrawer";
import {FiMail} from "react-icons/fi";

export const AvatarMenu = () => {
  const navigate = useNavigate();

  const {ikigaiUser, signOut} = useAuth();

  return (
    <Box>
      <Menu>
        <MenuList minWidth='240px'>
          <MenuItemOption onClick={() => {navigate("/kanban")}}  value='asc'>Kanban</MenuItemOption>
          <MenuItemOption onClick={() => {navigate("/profile")}}  value='asc'>Profile</MenuItemOption>
          <ComposeEmailDrawer
            OpenButton={(props) => <MenuItemOption  value='asc' {...props} >New Mail</MenuItemOption>}
          />
          <MenuItemOption onClick={signOut} value='asc'>Logout</MenuItemOption>
        </MenuList>
        <MenuButton
          as={Avatar}
          colorScheme='brand'
          name={ikigaiUser.name}
          src={ikigaiUser.picture}
        >
        </MenuButton>
      </Menu>
    </Box>
  )
}
