import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { Logo } from '../Components/Logo'
import { GoogleIcon } from '../Components/ProviderIcons'
import {useAuth} from "../Hooks/useAuth";

const Login = () => {

  const [password, setPassword] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");

  const { googleSignIn, signIn } = useAuth();

  return (
    <Container maxW="md" py={{base: '12', md: '24'}}>
      <Stack spacing="8">
        <img
          src={"IkigaiLogoText.png"}
          // style={{ height: 153 }}
          alt="website logo"
        />
        <Stack spacing="6">

          <Stack spacing={{base: '2', md: '3'}} textAlign="center">
            {/*<Heading size={useBreakpointValue({base: 'xs', md: 'sm'})}>*/}
            {/*  Log in to your account*/}
            {/*</Heading>*/}
            <Text color="muted">Bring Ikigai to your email</Text>
          </Stack>
        </Stack>
        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                placeholder="Enter your email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                id="password"
                placeholder="********"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
          </Stack>
          <HStack justify="space-between">
            <Checkbox defaultChecked>Remember me</Checkbox>
            <Button variant="link" colorScheme="brand" size="sm">
              Forgot password
            </Button>
          </HStack>
          <Stack spacing="4">
            <Button
              variant="primary"
              onClick={() => {signIn(email, password)}}
            >
              Sign in
            </Button>
            <Button
              onClick={() => {googleSignIn()}}
              variant="secondary"
              leftIcon={<GoogleIcon boxSize="5"/>}
              iconSpacing="3">
              Sign in with Google
            </Button>
          </Stack>
        </Stack>
        {/*<HStack spacing="1" justify="center">*/}
        {/*  <Text fontSize="sm" color="muted">*/}
        {/*    Don't have an account?*/}
        {/*  </Text>*/}
        {/*  <Button variant="link" colorScheme="brand" size="sm">*/}
        {/*    Sign up*/}
        {/*  </Button>*/}
        {/*</HStack>*/}
      </Stack>
    </Container>
  )
}


export default Login;
