
export const AwsConfigAuth = {
  region: process.env.REACT_APP_AUTH_REGION,
  userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  cookieStorage: {
    domain: process.env.REACT_APP_AUTH_COOKIE_STORAGE_DOMAIN,
    path: "/",
    expires: 365,
    sameSite: "strict",
    secure: true,
  },
  authenticationFlowType: "USER_SRP_AUTH",
  oauth: {
    // domain: `${
    //   import.meta.env.VITE_APP_COGNITO_DOMAIN +
    //   ".auth." +
    //   import.meta.env.VITE_APP_REGION +
    //   ".amazoncognito.com"
    // }`,
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    scope: ["email", "profile", "openid"],
    redirectSignIn: process.env.REACT_APP_URL + "/kanban",
    // redirectSignIn:
    //   import.meta.env.VITE_APP_API_STAGE === "prod"
    //     ? "production-url"
    //     : "http://localhost:3000", // Make sure to use the exact URL
    // // redirectSignOut:
    redirectSignOut: process.env.REACT_APP_URL,
    //   import.meta.env.VITE_APP_API_STAGE === "prod"
    //     ? "production-url"
    //     : "http://localhost:3000", // Make sure to use the exact URL
    responseType: "token",
  },
};
