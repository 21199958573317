import {useAuth} from "../Hooks/useAuth";
import {Text, VStack, Box, Button, Grid, GridItem, Card, CardBody} from "@chakra-ui/react";
import PrivateRoute from "../Components/PrivateRoute";
import KanbanBoard from "../Components/Kanban/KanbanBoard";

import { google }  from "../SDK/Gmail";
import React from "react";
// import { IkigaiContextProvider } from "../SDK/IkigaiContext";

export function SuccessPage() {
  // const auth = useAuth();
  // const [emails, setEmails] = React.useState<Ikigai.emailCard[]>([]);
  // const [loaded, setLoaded] = React.useState<boolean>(false);
  const { isLoading, googleAccessToken } = useAuth();

  const g = new google(googleAccessToken());

  if (isLoading) {
    return <Box />;
  }

  return (
    <PrivateRoute>
      {/*<IkigaiContext.Provider value={{*/}
      {/*  google: g,*/}
      {/*}}>*/}
      {/*  <KanbanBoard />*/}
      {/*</IkigaiContext.Provider>*/}
    </PrivateRoute>
  );
}
