import {useLocation} from "react-router-dom";
import {useAuth} from "../Hooks/useAuth";

const LoginCallback = () => {
  const queryParams = new URLSearchParams(useLocation().hash);

  const accessToken = queryParams.get("#access_token");

  const { user, isAuthenticated } = useAuth();

  if (isAuthenticated ){
    console.log("isAuthenticated");
    console.log(user);
  } else {
    console.log("Not Authenticated");
  }

  return (
    <>  </>
  )
}

export default LoginCallback
