import {Box, Flex, useBreakpointValue} from '@chakra-ui/react'
import * as React from 'react'
import { Footer } from './Footer'
import { Main } from './Main'
import { Sidebar } from './Sidebar'
import { Navbar } from './Navbar'


const Layout = (props: any) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  if (isDesktop) {
    return (
      // <Flex direction="column" flex="1">
      //   <Navbar/>
      //   <Main>{props.children}</Main>
      //   {/*<Navbar>*/}
      //   {/*</Navbar>*/}
      //   {/*<Container maxW={"max"}  flex="1">*/}
      //   {/*  <Stack direction={{ base: 'column', lg: 'row' }} spacing="0" flex="1">*/}
      //   {/*    <Sidebar maxW={{ lg: '36' }} />*/}
      //   {/*    <Main>{props.children}</Main>*/}
      //   {/*    /!*<Sidebar maxW={{ lg: '72' }} />*!/*/}
      //   {/*  </Stack>*/}
      //   {/*</Container>*/}
      //   <Footer />
      // </Flex>
      <Flex px={"0"}  direction="row" flex="1">
        <Sidebar maxW={{lg: '20'}}/>
        {/*<Sidebar />*/}
        {/*<Navbar />*/}
        <Flex bg={"white"} pl={'20'} direction="column" flex="1">
          <Main>{props.children}</Main>
          <Footer />
        </Flex>
      </Flex>
  )} else {
    return (
      <Flex px={"0"} direction="column" flex="1">
        <Navbar />
        <Box pt={20}>
        <Main>{props.children}</Main>
        </Box>
        <Footer />
      </Flex>
    )}
}

export default Layout;
