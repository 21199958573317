import { HTMLChakraProps } from '@chakra-ui/react'

export const Logo = (props: HTMLChakraProps<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    height={"3rem"}
    width={"3rem"}
    // width="161px"
    // height="161px"
    // viewBox="-0.5 -0.5 161 161"
    viewBox="0 0 161 161"
  >
    <defs/>
    <g>
      <path d="M 20 60 L 60 100 L 20 140 Z" fill="#8c0178" stroke="#ffffff" stroke-miterlimit="10" transform="rotate(90,40,100)" pointer-events="all"/>
      <path d="M 20 -20 L 60 20 L 20 60 Z" fill="#8c0178" stroke="#ffffff" stroke-miterlimit="10" transform="rotate(90,40,20)" pointer-events="all"/>
      <path d="M 80 80 L 120 120 L 80 160 Z" fill="#8c0178" stroke="#ffffff" stroke-miterlimit="10" pointer-events="all"/>
      <path d="M 0 80 L 40 120 L 0 160 Z" fill="#8c0178" stroke="#ffffff" stroke-miterlimit="10" pointer-events="all"/>
      <path d="M 20 20 L 60 60 L 20 100 Z" fill="#8c0178" stroke="#ffffff" stroke-miterlimit="10" transform="rotate(-90,40,60)" pointer-events="all"/>
      <path d="M 100 100 L 140 140 L 100 180 Z" fill="#8c0178" stroke="#ffffff" stroke-miterlimit="10" transform="rotate(-90,120,140)" pointer-events="all"/>
      <path d="M 40 80 L 80 120 L 40 160 Z" fill="#8c0178" stroke="#ffffff" stroke-miterlimit="10" transform="rotate(-180,60,120)" pointer-events="all"/>
      <path d="M 40 0 L 80 40 L 40 80 Z" fill="#8c0178" stroke="#ffffff" stroke-miterlimit="10" transform="rotate(-180,60,40)" pointer-events="all"/>
    </g>
  </svg>

)

export const LogoText = (props: HTMLChakraProps<'svg'>) => (
  <svg
    // width="708pt"
    // height="317.03998pt"
    height={"3rem"}
    width={"auto"}
    viewBox="0 0 708 317.03998"
    version="1.1"
    id="svg65"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs
      id="defs23">
      <g
        id="g21">
        <g
          id="glyph-0-0" />
        <g
          id="glyph-0-1">
          <path
            d="M 10.25 0 L 10.25 -107.375 L 31.9375 -107.375 L 31.9375 0 Z M 10.25 0 "
            id="path3" />
        </g>
        <g
          id="glyph-0-2">
          <path
            d="M 11.203125 0 L 11.203125 -107.375 L 32.890625 -107.375 L 32.890625 -59.6875 L 76.6875 -107.375 L 105.828125 -107.375 L 65.40625 -65.546875 L 108.03125 0 L 79.984375 0 L 50.46875 -50.390625 L 32.890625 -32.453125 L 32.890625 0 Z M 11.203125 0 "
            id="path6" />
        </g>
        <g
          id="glyph-0-3">
          <path
            d="M 60.859375 -39.484375 L 60.859375 -57.5625 L 107.59375 -57.5625 L 107.59375 -14.796875 C 103.050781 -10.398438 96.46875 -6.53125 87.84375 -3.1875 C 79.226563 0.15625 70.503906 1.828125 61.671875 1.828125 C 50.441406 1.828125 40.648438 -0.523438 32.296875 -5.234375 C 23.953125 -9.953125 17.675781 -16.691406 13.46875 -25.453125 C 9.269531 -34.210938 7.171875 -43.742188 7.171875 -54.046875 C 7.171875 -65.234375 9.515625 -75.171875 14.203125 -83.859375 C 18.890625 -92.554688 25.753906 -99.222656 34.796875 -103.859375 C 41.679688 -107.421875 50.25 -109.203125 60.5 -109.203125 C 73.832031 -109.203125 84.242188 -106.40625 91.734375 -100.8125 C 99.234375 -95.226563 104.054688 -87.503906 106.203125 -77.640625 L 84.671875 -73.609375 C 83.148438 -78.878906 80.300781 -83.039063 76.125 -86.09375 C 71.957031 -89.144531 66.75 -90.671875 60.5 -90.671875 C 51.03125 -90.671875 43.5 -87.664063 37.90625 -81.65625 C 32.3125 -75.65625 29.515625 -66.75 29.515625 -54.9375 C 29.515625 -42.1875 32.347656 -32.625 38.015625 -26.25 C 43.679688 -19.882813 51.101563 -16.703125 60.28125 -16.703125 C 64.820313 -16.703125 69.375 -17.59375 73.9375 -19.375 C 78.5 -21.15625 82.414063 -23.316406 85.6875 -25.859375 L 85.6875 -39.484375 Z M 60.859375 -39.484375 "
            id="path9" />
        </g>
        <g
          id="glyph-0-4">
          <path
            d="M 107.734375 0 L 84.15625 0 L 74.78125 -24.390625 L 31.859375 -24.390625 L 23 0 L 0 0 L 41.828125 -107.375 L 64.75 -107.375 Z M 67.828125 -42.484375 L 53.03125 -82.328125 L 38.53125 -42.484375 Z M 67.828125 -42.484375 "
            id="path12" />
        </g>
        <g
          id="glyph-0-5">
          <path
            d="M 10.625 0 L 10.625 -107.375 L 43.0625 -107.375 L 62.546875 -34.125 L 81.8125 -107.375 L 114.328125 -107.375 L 114.328125 0 L 94.1875 0 L 94.1875 -84.515625 L 72.875 0 L 52 0 L 30.765625 -84.515625 L 30.765625 0 Z M 10.625 0 "
            id="path15" />
        </g>
        <g
          id="glyph-0-6">
          <path
            d="M 11.5 0 L 11.5 -106.5 L 33.171875 -106.5 L 33.171875 -18.09375 L 87.078125 -18.09375 L 87.078125 0 Z M 11.5 0 "
            id="path18" />
        </g>
      </g>
    </defs>
    <path
      fill-rule="nonzero"
      fill="rgb(54.899597%, 0.389099%, 47.059631%)"
      fill-opacity="1"
      stroke-width="2"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke="rgb(100%, 100%, 100%)"
      stroke-opacity="1"
      stroke-miterlimit="10"
      d="M 42.000009 169.999993 L 122.000012 249.999997 L 42.000009 330 Z M 42.000009 169.999993 "
      transform="matrix(0.000000000000000046, 0.75, -0.75, 0.000000000000000046, 248.99999, 125.999995)"
      id="path25" />
    <path
      fill-rule="nonzero"
      fill="rgb(54.899597%, 0.389099%, 47.059631%)"
      fill-opacity="1"
      stroke-width="2"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke="rgb(100%, 100%, 100%)"
      stroke-opacity="1"
      stroke-miterlimit="10"
      d="M 42.000002 9.999993 L 122.000005 89.999997 L 42.000002 170 Z M 42.000002 9.999993 "
      transform="matrix(0.000000000000000046, 0.75, -0.75, 0.000000000000000046, 128.999995, 6)"
      id="path27" />
    <path
      fill-rule="nonzero"
      fill="rgb(54.899597%, 0.389099%, 47.059631%)"
      fill-opacity="1"
      stroke-width="2"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke="rgb(100%, 100%, 100%)"
      stroke-opacity="1"
      stroke-miterlimit="10"
      d="M 162.000007 210.000009 L 242.00001 290.000012 L 162.000007 370.000015 Z M 162.000007 210.000009 "
      transform="matrix(0.75, 0, 0, 0.75, 0, 0)"
      id="path29" />
    <path
      fill-rule="nonzero"
      fill="rgb(54.899597%, 0.389099%, 47.059631%)"
      fill-opacity="1"
      stroke-width="2"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke="rgb(100%, 100%, 100%)"
      stroke-opacity="1"
      stroke-miterlimit="10"
      d="M 2 210.000009 L 82.000003 290.000012 L 2 370.000015 Z M 2 210.000009 "
      transform="matrix(0.75, 0, 0, 0.75, 0, 0)"
      id="path31" />
    <path
      fill-rule="nonzero"
      fill="rgb(54.899597%, 0.389099%, 47.059631%)"
      fill-opacity="1"
      stroke-width="2"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke="rgb(100%, 100%, 100%)"
      stroke-opacity="1"
      stroke-miterlimit="10"
      d="M 41.999991 90 L 121.999995 170.000003 L 41.999991 250.000007 Z M 41.999991 90 "
      transform="matrix(0.000000000000000046, -0.75, 0.75, 0.000000000000000046, -65.999997, 188.999992)"
      id="path33" />
    <path
      fill-rule="nonzero"
      fill="rgb(54.899597%, 0.389099%, 47.059631%)"
      fill-opacity="1"
      stroke-width="2"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke="rgb(100%, 100%, 100%)"
      stroke-opacity="1"
      stroke-miterlimit="10"
      d="M 201.999985 250.000007 L 281.999988 330.00001 L 201.999985 410.000013 Z M 201.999985 250.000007 "
      transform="matrix(0.000000000000000046, -0.75, 0.75, 0.000000000000000046, -65.999997, 428.999982)"
      id="path35" />
    <path
      fill-rule="nonzero"
      fill="rgb(54.899597%, 0.389099%, 47.059631%)"
      fill-opacity="1"
      stroke-width="2"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke="rgb(100%, 100%, 100%)"
      stroke-opacity="1"
      stroke-miterlimit="10"
      d="M 81.999993 209.999985 L 161.999997 289.999988 L 81.999993 369.999991 Z M 81.999993 209.999985 "
      transform="matrix(-0.75, -0.000000000000000092, 0.000000000000000092, -0.75, 182.999992, 434.999982)"
      id="path37" />
    <path
      fill-rule="nonzero"
      fill="rgb(54.899597%, 0.389099%, 47.059631%)"
      fill-opacity="1"
      stroke-width="2"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke="rgb(100%, 100%, 100%)"
      stroke-opacity="1"
      stroke-miterlimit="10"
      d="M 81.999993 49.999991 L 161.999997 129.999995 L 81.999993 209.999998 Z M 81.999993 49.999991 "
      transform="matrix(-0.75, -0.000000000000000092, 0.000000000000000092, -0.75, 182.999992, 194.999992)"
      id="path39" />
    <g
      fill="#b8b6b9"
      fill-opacity="1"
      id="g53">
      <use
        // xlink:href="#glyph-0-1"
        x="245.15623"
        y="144"
        id="use41" />
      <use
        // xlink:href="#glyph-0-2"
        x="286.83105"
        y="144"
        id="use43" />
      <use
        // xlink:href="#glyph-0-1"
        x="395.15622"
        y="144"
        id="use45" />
      <use
        // xlink:href="#glyph-0-3"
        x="436.83102"
        y="144"
        id="use47" />
      <use
        // xlink:href="#glyph-0-4"
        x="553.50586"
        y="144"
        id="use49" />
      <use
        // xlink:href="#glyph-0-1"
        x="661.83105"
        y="144"
        id="use51" />
    </g>
    <g
      fill="#a23793"
      fill-opacity="1"
      id="g63">
      <use
        // xlink:href="#glyph-0-5"
        x="336.9375"
        y="279"
        id="use55" />
      <use
        // xlink:href="#glyph-0-4"
        x="461.88864"
        y="279"
        id="use57" />
      <use
        // xlink:href="#glyph-0-1"
        x="570.21387"
        y="279"
        id="use59" />
      <use
        // xlink:href="#glyph-0-6"
        x="611.88867"
        y="279"
        id="use61" />
    </g>
  </svg>
)
