import {
  Box,
  BoxProps,
  Container,
  Stack,
  HStack,
  ButtonGroup,
  IconButton,
  Text
} from '@chakra-ui/react'
import { FaTwitter} from 'react-icons/fa'
import * as React from 'react'
import { Logo } from '../Logo'

export const Footer = (props: BoxProps) => {
  const year = new Date().getFullYear()
  return (
    <Box as="footer" role="footer"  {...props}>
      <Container as="footer" bg={"white"} role="footer" py={{ base: '12', md: '16' }}>
        <Stack spacing={{ base: '4', md: '5' }} >
          <HStack justify="space-between" direction="row" px={"0"} >
            <Logo />
            <ButtonGroup variant="ghost">
              {/*<IconButton*/}
              {/*  as="a"*/}
              {/*  href="#"*/}
              {/*  aria-label="LinkedIn"*/}
              {/*  icon={<FaLinkedin fontSize="1.25rem" />}*/}
              {/*/>*/}
              {/*<IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub fontSize="1.25rem" />} />*/}
              <IconButton
                as="a"
                href="https://twitter.com/ikigaimail"
                aria-label="Twitter"
                icon={<FaTwitter fontSize="1.25rem" />}
              />
            </ButtonGroup>
          </HStack>
          <Text>Crafted with <span style={{ color: 'red' }}> ❤ </span> in Boston</Text>
          <Text fontSize="sm" color="subtle">
            &copy; {year} EPIPHYTE LLC, All rights reserved.
          </Text>
        </Stack>
      </Container>
    </Box>
  )
}
