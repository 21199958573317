import {Flex, Text, Box, useBreakpointValue} from "@chakra-ui/react";
import { useDroppable } from "@dnd-kit/core";
import KanbanCard from "./KanbanCard";
import React from "react";


interface KanbanColumnProps {
  title: string;
  id: string;
  color: string;
  items: any[];
}

export default function KanbanLane({ title, id, color, items }: KanbanColumnProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const { setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <Flex  flex="3" padding="2" flexDirection="column" minH="10rem">

      <Flex
        ref={setNodeRef}
        // backgroundColor="gray.200"
        backgroundColor={"whitesmoke"}
        borderRadius="0"
        flex="1"
        padding="0"
        flexDirection="column"
        // overflow='hidden'
      >
        <Box bg={color} borderTopRadius={"0"} w='100%' p={3} color='white'>
          <Text fontSize="l" fontWeight="bold"> {title}</Text>
        </Box>
        {/*<Text fontSize="xl" color={"whitesmoke"} backgroundColor={color} fontWeight="bold"> {title}</Text>*/}
        {items.map((item, key) => (
          <KanbanCard email={item} key={key} index={key} parent={id} />
        ))}
      </Flex>
    </Flex>
  );
}
