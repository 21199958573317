import {Box, BoxProps, VStack, Container, useColorModeValue, Button} from '@chakra-ui/react'
import * as React from 'react'
import { Flex, Icon, IconButton, Spacer, Stack } from '@chakra-ui/react'
import { AvatarMenu } from "../AvatarMenu";
import {
  BsKanbanFill,
} from 'react-icons/bs'
import {
  FaCog,
} from 'react-icons/fa'
import {
  FiMail,
} from 'react-icons/fi'
import {RadioButton, RadioButtonGroup} from "../RadioButtonGroup";
import ComposeEmailDrawer from "../Email/ComposeEmailDrawer";
import {useNavigate} from "react-router-dom";


export const Sidebar = (props: BoxProps) => {

 const navigate = useNavigate();

  console.log(window.location.pathname);

  return (
    <Box
      as="aside"
      role="complementary"
      alignSelf="start"
      height={"100vh"}
      position="fixed"
      // height={"100%"}
      // position={{ base: 'unset', lg: 'sticky' }}
      bg="gray.100"
      boxShadow={useColorModeValue('sm', 'sm-dark')}
      py={{ base: '3', lg: '4' }}
      px={"1"}
      // top="36"
      {...props}
    >
      {/*<Container py={{ base: '3', lg: '4' }} px={"2"}>*/}
        <Flex height={"100%"} direction={"column"} >
          <Box>
            <img
              src={"IkigaiLogo.png"}
              style={{
                height: 42,
                width: 42
              }}
              alt="website logo"
            />
          </Box>


          <VStack pt={"80px"}>
            <IconButton
              aria-label={"Kanban View"}
              onClick={() => {navigate('/kanban')}}
              icon={<Icon as={BsKanbanFill}
                    _hover={{ color: "brand.600", stroke: "brand.600", bg:"white" }}
                    rounded={5}
                    onClick={() => {}}
                    color={"brand.900"}
                    boxSize={10}
              />}
            />
            <IconButton
              aria-label={"Settings"}
              onClick={() => {navigate('/profile')}}
              icon={<Icon as={FaCog}
                  _hover={{ color: "brand.600", stroke: "brand.600", bg:"white" }}
                  rounded={5}
                  onClick={() => {}}
                  color={"brand.900"}
                  boxSize={10}
            />}
            />
            <ComposeEmailDrawer
              OpenButton={(props) => <Icon as={FiMail}
                                           _hover={{ color: "brand.600", stroke: "brand.600", bg:"white" }}
                                           rounded={5}
                                           color={"brand.900"}
                                           boxSize={10}
                                           {...props}/>}
            />

          </VStack>


          <Spacer />
          <AvatarMenu/>
        </Flex>


      {/*</Container>*/}
    </Box>
  )
}
