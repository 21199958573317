import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from "@chakra-ui/react";
import { ProvideAuth } from "./Hooks/useAuth";
import { extendTheme } from "@chakra-ui/react"
import { theme as proTheme } from '@chakra-ui/pro-theme'
import "@fontsource/gothic-a1"


export const theme = extendTheme(
  {
    fonts: {
      heading: `'Gothic A1', sans-serif`,
      body: `'Gothic A1', sans-serif`,
    },
    colors: {
      // "gray": "#b8b6b9",
      "dark": "#0d000b",
      // ...proTheme.colors,
      brand: {
        "gray": "#b8b6b9",
        "50": "#fd29df",
        "100": "#fd0fdb",
        "200": "#f102cf",
        "300": "#d802b9",
        "400": "#bf01a3",
        "500": "#8c0178",
        "600": "#730162",
        "700": "#59014d",
        "800": "#400037",
        "900": "#270021"
      }
    },
  },
  proTheme,
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ProvideAuth>
        <App />
      </ProvideAuth>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
