import axios from 'axios';
import { google } from './Gmail';

export class Ikigai {

    private google: google;

    constructor(google: google) {
        this.google = google;
    }

    async CheckIkigaiInstall() {
        const labels = await this.google.listGmailLabels();
        const ikigaiLabels = labels.filter((label: any) => {
            return label.name.startsWith("IKIGAI/");
        });

        if (ikigaiLabels.length === 0) {
            await this.google.Post("/gmail/v1/users/me/labels", {
                "messageListVisibility": "hide",
                "name": "IKIGAI",
                "labelListVisibility": "labelHide"
            });
            await this.google.Post("/gmail/v1/users/me/labels", {
                "messageListVisibility": "hide",
                "name": "IKIGAI/TODO",
                "labelListVisibility": "labelHide"
            });
            await this.google.Post("/gmail/v1/users/me/labels", {
                "messageListVisibility": "hide",
                "name": "IKIGAI/IN_PROGRESS",
                "labelListVisibility": "labelHide"
            });
            await this.google.Post("/gmail/v1/users/me/labels", {
                "messageListVisibility": "hide",
                "name": "IKIGAI/DONE",
                "labelListVisibility": "labelHide"
            });
        }
    }

}


export function generateRawMimeMessage(from: string, to: Array<string>, subject: string, body: string) {
    let recipients = to[0]
    for (let i: number = 1; i < to.length; i++) {
        recipients = recipients +","+to[i]
    }

    let rawMimeMessage = "From: " + encodeURI(from) + "\r\n" +
      "To: " + to + "\r\n" +
      "Subject: " + subject + "\r\n" +
      "Content-Type: text/plain; charset=UTF-8\r\n" +
      "\r\n" +
      body;

    return rawMimeMessage;
}


export const getGmailLabels = async (GoogleAccessToken: string) => {
    const param = {
        AccessToken: GoogleAccessToken
    }
    console.log(param);
    const { data } = await axios.post('http://localhost:8090/gmail/labels', param);
    return data
}
