import {Text, Box, Flex, VStack, useDisclosure} from "@chakra-ui/react";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import EmailDrawer from "../Email/EmailDrawer";

const KanbanCard = ({email, index, parent,}: {
  email: Ikigai.emailCard;
  index: number;
  parent: string;
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: email.id,
    data: {
      email,
      index,
      parent,
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure()

  const style = {
    transform: CSS.Translate.toString(transform),
  };

  let backgroundColor = "white";
  if (email.labels.includes("UNREAD")) {
    backgroundColor = "brand.gray";
  }

  return (
    <Flex
      padding="0"
      paddingLeft={0}
      backgroundColor={backgroundColor}
      margin="2"
      borderRadius="0 "
      border="2px solid gray.500"
      boxShadow="0px 0px 5px 2px #2121213b"
      overflow='hidden'
      transform={style.transform}
      {...listeners}
      {...attributes}
      onClick={onOpen}
      ref={setNodeRef}
    >
      <EmailDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} email={email}/>
      <Box p={1} bg={"brand.500"}/>
      <VStack p={2} alignItems={"left"}>
        <Text fontSize={"sm"} color={"brand.500"}  fontWeight={"bold"} alignSelf={"left"} >{email!.subject}</Text>
        <Text fontSize={"xs"} textAlign={"left"} fontWeight={"bold"}>{email!.from}</Text>
        <Text fontSize={"xs"}> {email!.snippet}</Text>
      </VStack>
    </Flex>
  );
};

export default KanbanCard;
