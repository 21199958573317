import {Amplify, Auth} from "aws-amplify";
import React, {createContext, useContext, useEffect, useState} from "react";
import {AwsConfigAuth} from "../SDK/congito";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";

Amplify.configure({ Auth: AwsConfigAuth });

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  username: string;
  user: any;
  ikigaiUser: Ikigai.user;
  googleAccessToken: () => string;
  signIn: (username: string, password: string) => Promise<Result>;
  googleSignIn: () => Promise<void>;
  signOut: () => void;
}

interface Result {
  success: boolean;
  message: string;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};



export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [user, setUser] = useState<Ikigai.cognitoUser>({} as Ikigai.cognitoUser);
  const [ikigaiUser, setIkigaiUser] = useState<Ikigai.user>({} as Ikigai.user);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((result) => {

        setUser(result);
        setUsername(result.username);
        const payload = result.signInUserSession.idToken.payload;

        setIkigaiUser({
          username: result.username,
          email: payload.email,
          name: payload.given_name,
          familyName: payload.family_name,
          picture: payload.picture,
          displayName: payload.given_name + " " + payload.family_name,
          identity: "" + payload.given_name + " " + payload.family_name + " <" + payload.email + ">",
        })
        setIsAuthenticated(true);
        setIsLoading(false);
      })
      .catch(() => {
        setUsername("");
        setIsAuthenticated(false);
        setIsLoading(false);
      });
  }, []);

  const signIn = async (username: string, password: string) => {
    try {
      const result = await Auth.signIn(username, password);
      setUsername(result.username);
      setIsAuthenticated(true);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "LOGIN FAIL",
      };
    }
  };

  // Trigger Google login
  const googleSignIn = async () => {
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  };

  const googleAccessToken = (): string => {
    return user.signInUserSession.idToken.payload["custom:google_access_token"];
  }


  const signOut = async () => {
    try {
      await Auth.signOut();
      setUsername("");
      setIsAuthenticated(false);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "LOGOUT FAIL",
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    username,
    user,
    ikigaiUser,
    googleAccessToken,
    signIn,
    googleSignIn,
    signOut,
  };
};
