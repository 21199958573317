import { useState, useContext } from "react";
import { DndContext, rectIntersection } from "@dnd-kit/core";
import KanbanLane from "./KanbanLane";
import {Flex, useBreakpointValue, useDisclosure} from "@chakra-ui/react";
import { IkigaiContext } from "../../SDK/IkigaiContext";
import EmailDrawer from "../Email/EmailDrawer";
import {useSwipeable} from "react-swipeable";


// type Props = {
//   todoItems: Ikigai.emailCard[];
//   setTodoItems: (items: Ikigai.emailCard[]) => void;
//   inProgressItems: Ikigai.emailCard[];
//   setInProgressItems: (items: Ikigai.emailCard[]) => void;
//   doneItems: Ikigai.emailCard[];
//   setDoneItems: (items: Ikigai.emailCard[]) => void;
//   unreadItems: Ikigai.emailCard[];
//   setUnreadItems: (items: Ikigai.emailCard[]) => void;
// }

export default function KanbanBoard() {

  const { google, emails, setEmails, todoItems, setTodoItems, setUnreadItems, setInProgressItems, inProgressItems, unreadItems, setDoneItems, doneItems} = useContext(IkigaiContext);

  const [loaded, setLoaded] = useState<boolean>(false);
  // const [todoItems, setTodoItems] = useState<Array<Ikigai.emailCard>>([]);
  // const [doneItems, setDoneItems] = useState<Array<Ikigai.emailCard>>([]);
  // const [inProgressItems, setInProgressItems] = useState<Array<Ikigai.emailCard>>([]);
  // const [unreadItems, setUnreadItems] = useState<Array<Ikigai.emailCard>>([]);
  const [kanbanColumnIdx, setKanbanColumnIdx] = useState<number>(0);

  const [selectedEmail, setSelectedEmail] = useState<Ikigai.emailCard | undefined>(undefined);

  const swiapableHandlers = useSwipeable({
    onSwipedLeft: () => {
      let idx = kanbanColumnIdx + 1;
      if (idx > 3) {
        idx = 3;
      }
      setKanbanColumnIdx(idx)
    },
    onSwipedRight: () => {
      let idx = kanbanColumnIdx - 1;
      if (idx < 0) {
        idx = 0;
      }
      setKanbanColumnIdx(idx)
    },
    // onSwiped: (eventData) => console.log("User Swiped!", eventData),
    // ...config,
  });

  const ctx = useContext(IkigaiContext)
  const g = ctx!.google;

  // Opens the email drawer
  const { isOpen, onOpen, onClose } = useDisclosure()


  const isDesktop = useBreakpointValue({ base: false, lg: true })

  // if (!loaded) {
  //
  //   g.getKanbanEmails().then((emails) => {
  //     setTodoItems([...todoItems, ...emails.todo]);
  //     setDoneItems([...doneItems, ...emails.done]);
  //     setInProgressItems([...inProgressItems, ...emails.inProgress]);
  //     setUnreadItems([...unreadItems, ...emails.unassigned]);
  //     setLoaded(true)
  //   })
  //
  //   ctx!.google.listGmailThreads().then((result) => {});
  // }

  const onDragEnd = (e: any) => {
    const container = e.over?.id;
    const email = e.active.data.current?.email ?? "";
    const index = e.active.data.current?.index ?? 0;
    const parent = e.active.data.current?.parent ?? "";


    if (container === parent || container === undefined) {
      setSelectedEmail(email);
      //Marks the message as read
      ctx!.google.markMessageAsRead(email.id).then(() => {});
      onOpen();
      return;
    }

    //Drag and drop functionality only on Desktop
  if(isDesktop) {
    if (container === "IKIGAI/TODO") {
      g.setMessageLane(email.id, parent, "IKIGAI/TODO").then(() => {
        setTodoItems([...todoItems, email]);
      });
    } else if (container === "IKIGAI/DONE") {
      g.setMessageLane(email.id, parent, "IKIGAI/DONE").then(() => {
        setDoneItems([...doneItems, email]);
      });
    } else if (container === "IKIGAI/UNASSIGNED") {
      g.setMessageLane(email.id, parent, "IKIGAI/UNASSIGNED").then(() => {
        setUnreadItems([...unreadItems, email]);
      });
    } else {
      g.setMessageLane(email.id, parent, "IKIGAI/IN_PROGRESS").then(() => {
        setInProgressItems([...inProgressItems, email]);
      });
    }

    if (parent === "IKIGAI/TODO") {
      setTodoItems([
        ...todoItems.slice(0, index),
        ...todoItems.slice(index + 1),
      ]);
    } else if (parent === "IKIGAI/DONE") {
      setDoneItems([
        ...doneItems.slice(0, index),
        ...doneItems.slice(index + 1),
      ]);
    } else if (parent === "IKIGAI/UNASSIGNED") {
      setUnreadItems([
        ...unreadItems.slice(0, index),
        ...unreadItems.slice(index + 1)
      ]);
    } else {
      setInProgressItems([
        ...inProgressItems.slice(0, index),
        ...inProgressItems.slice(index + 1),
      ]);
    }
  }
  }


    const kanbanColumns = [
      (<KanbanLane title="Unassigned" id={"IKIGAI/UNASSIGNED"} color="brand.400" items={unreadItems}/>),
      (<KanbanLane title="Defer | ToDo" id={"IKIGAI/TODO"} color="brand.500" items={todoItems}/>),
      (<KanbanLane title="In Progress" id={"IKIGAI/IN_PROGRESS"} color="brand.700" items={inProgressItems}/>),
      (<KanbanLane title="Done" id={"IKIGAI/DONE"} color="brand.800" items={doneItems}/>)
    ]

    return (
      <>
        <EmailDrawer isOpen={isOpen} onOpen={onOpen} onClose={onClose} email={selectedEmail}/>


          { isDesktop ? (
            <DndContext
              collisionDetection={rectIntersection}
              onDragEnd={onDragEnd}
            >
            <Flex flex="1">
              {kanbanColumns.map((col) => col)}
            </Flex>
            </DndContext>
            ) : (
            <>
              <div {...swiapableHandlers}>
                {kanbanColumns[kanbanColumnIdx]}
              </div>
            </>
            )}

      </>
    );

}
