import {google} from "./Gmail";
import React from "react";

export interface IkigaiContextType {
  google: google;
  emails: Ikigai.KanbanMails;
  setEmails: React.Dispatch<React.SetStateAction<Ikigai.KanbanMails>>;
  todoItems: Ikigai.emailCard[];
  setTodoItems: React.Dispatch<React.SetStateAction<Ikigai.emailCard[]>>;
  inProgressItems: Ikigai.emailCard[];
  setInProgressItems: React.Dispatch<React.SetStateAction<Ikigai.emailCard[]>>;
  doneItems: Ikigai.emailCard[];
  setDoneItems: React.Dispatch<React.SetStateAction<Ikigai.emailCard[]>>;
  unreadItems: Ikigai.emailCard[];
  setUnreadItems: React.Dispatch<React.SetStateAction<Ikigai.emailCard[]>>;
}

export const IkigaiContext = React.createContext<IkigaiContextType>({
  google: new google(""),
  emails: {} as Ikigai.KanbanMails,
  setEmails: () => {},
  todoItems: [],
  setTodoItems: () => {},
  inProgressItems: [],
  setInProgressItems: () => {},
  doneItems: [],
  setDoneItems: () => {},
  unreadItems: [],
  setUnreadItems: () => {},
});
export const IkigaiContextConsumer = IkigaiContext.Consumer;

// export const IkigaiContextProvider = ({ children }) => {
//   const [todoItems, setTodoItems] = useState<Array<Ikigai.emailCard>>([]);
//   const [doneItems, setDoneItems] = useState<Array<Ikigai.emailCard>>([]);
//   const [inProgressItems, setInProgressItems] = useState<Array<Ikigai.emailCard>>([]);
//   const [unreadItems, setUnreadItems] = useState<Array<Ikigai.emailCard>>([]);
//
//   return (
//     <IkigaiContext.Provider value={{
//       google,
//       emails,
//       setEmails,
//       todoItems,
//       setTodoItems,
//       inProgressItems,
//       setInProgressItems,
//       doneItems,
//       setDoneItems,
//       unreadItems,
//       setUnreadItems
//
//     }}>
//         {children}
//     </IkigaiContext.Provider>
//   );
// }


