import React, {createContext, useState} from 'react';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import './App.css';
import Login from "./Pages/Login";
import Kanban from "./Pages/Kanban";
import {SuccessPage} from "./Pages/Success";
import Profile from "./Pages/Profile";
import LoginCallback from "./Pages/LoginCallback";

import {
  Box,
} from "@chakra-ui/react";



import '@aws-amplify/ui-react/styles.css';
import { useAuth } from "./Hooks/useAuth";
import {google} from "./SDK/Gmail";
import { Ikigai } from "./SDK/Ikigai";
import {IkigaiContext} from "./SDK/IkigaiContext";


function App() {
  const {isLoading, isAuthenticated, googleAccessToken} = useAuth();

  const [emails, setEmails] = useState<Ikigai.KanbanMails>({} as Ikigai.KanbanMails);
  const [todoItems, setTodoItems] = useState<Array<Ikigai.emailCard>>([]);
  const [doneItems, setDoneItems] = useState<Array<Ikigai.emailCard>>([]);
  const [inProgressItems, setInProgressItems] = useState<Array<Ikigai.emailCard>>([]);
  const [unreadItems, setUnreadItems] = useState<Array<Ikigai.emailCard>>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  if (isLoading) {
    return <Box />;
  }

  if (isAuthenticated) {
    const g = new google(googleAccessToken());
    const ikigai = new Ikigai(g);
    if(!loaded) {
      ikigai.CheckIkigaiInstall().then(() => {
        g.getProfile().then((result) => {
          // console.log(result);
        });
        g.listGmailLabels().then((result) => {
          // console.log(result);
        });
        g.getKanbanEmails().then((emails) => {
          setTodoItems([...todoItems, ...emails.todo]);
          setDoneItems([...doneItems, ...emails.done]);
          setInProgressItems([...inProgressItems, ...emails.inProgress]);
          setUnreadItems([...unreadItems, ...emails.unassigned]);
          setLoaded(true);
        })
      });
    }


    return (
      <IkigaiContext.Provider value={{
        google: g,
        emails: emails,
        setEmails: setEmails,
        todoItems: todoItems,
        setTodoItems: setTodoItems,
        inProgressItems: inProgressItems,
        setInProgressItems: setInProgressItems,
        doneItems: doneItems,
        setDoneItems: setDoneItems,
        unreadItems: unreadItems,
        setUnreadItems: setUnreadItems
      }}>
        <BrowserRouter>
          <Routes>
            <Route index element={<Kanban />} />
            <Route path="login" element={<Login />} />
            <Route path="kanban" element={<Kanban />} />
            <Route path={"profile"} element={<Profile/>} />
            <Route path="login-callback" element={<LoginCallback />}></Route>
            <Route path="success" element={<SuccessPage />}></Route>
            <Route path="*" element={<p>Page Not Found</p>} />
          </Routes>
        </BrowserRouter>
      </IkigaiContext.Provider>
    );
  } else {
    return (
      <BrowserRouter>
        <Routes>
          <Route index element={<Login />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    )
  }

}

export default App;
