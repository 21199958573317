import { Box } from '@chakra-ui/react'
import * as React from 'react'


export const Main = (props: any) => {
  return (
    <Box as="main" role="main" width="full" >
      {props.children}
    </Box>
  )
}
