import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { FiMenu } from 'react-icons/fi'
import { Logo } from '../Logo'
import {useAuth} from "../../Hooks/useAuth";
import {AvatarMenu} from "../AvatarMenu";

export const Navbar = ( props:any ) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  const {ikigaiUser, signOut} = useAuth();

  return (
    // <Box  as="section" pb={'0'}>
      <Flex as="header" position={"fixed"} width={"100%"} top={0}>
      {/*<Box as="nav" bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')}>*/}
        <Box px={5} width="100%" bg="bg-surface" boxShadow={useColorModeValue('sm', 'sm-dark')}>
        <Container px={"0"} maxW={"100%"} py={{ base: '2', lg: '2' }}>
          <Flex px={"0"} justify="space-between">
            <HStack px={"0"} spacing="4">
              <Logo />
              {isDesktop && (
                <ButtonGroup variant="ghost" spacing="1">
                  <Button>Home</Button>
                  <Button aria-current="page">Kanban</Button>
                  {/*<Button>Tasks</Button>*/}
                  {/*<Button>Bookmarks</Button>*/}
                  {/*<Button>Users</Button>*/}
                </ButtonGroup>
              )}
            </HStack>
            {isDesktop ? (
              <HStack spacing="4">
                {/*<ButtonGroup variant="ghost" spacing="1">*/}
                {/*  <IconButton icon={<FiSearch fontSize="1.25rem" />} aria-label="Search" />*/}
                {/*  <IconButton icon={<FiSettings fontSize="1.25rem" />} aria-label="Settings" />*/}
                {/*  <IconButton icon={<FiHelpCircle fontSize="1.25rem" />} aria-label="Help Center" />*/}
                {/*</ButtonGroup>*/}
                <Menu>
                  <MenuButton
                    as={Avatar}
                    colorScheme='brand'
                    name={ikigaiUser.name}
                    src={ikigaiUser.picture}
                  >
                  </MenuButton>
                  <MenuList minWidth='240px'>
                    <MenuItemOption onClick={signOut} value='asc'>Logout</MenuItemOption>
                  </MenuList>
                </Menu>
              </HStack>
            ) : (
              <AvatarMenu />
              // <IconButton
              //   variant="ghost"
              //   icon={<FiMenu fontSize="1.25rem" />}
              //   aria-label="Open Menu"
              // />
            )}
          </Flex>
        </Container>
      </Box>
    </Flex>
  )
}
