import {
  Button,
  Badge,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  useBreakpointValue,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  HStack,
  Input,
  Textarea,
  VStack, Select,
  useToast
} from "@chakra-ui/react";
import React, {useContext, ReactNode} from "react";
import {generateRawMimeMessage} from "../../SDK/Ikigai";
import {IkigaiContext, IkigaiContextConsumer} from "../../SDK/IkigaiContext";
import {useAuth} from "../../Hooks/useAuth";

type Props = {
  email?: Ikigai.emailCard;
  OpenButton: React.ElementType;
}

const ComposeEmailDrawer = (props: Props) => {
  const {ikigaiUser, signOut} = useAuth();

  const [to, setTo] = React.useState<Array<string>>([]);
  const [from , setFrom] = React.useState<string>(ikigaiUser.identity);
  const [subject, setSubject] = React.useState<string>("");
  const [emailContent, setEmailContent] = React.useState<string>("");
  const [error, setError] = React.useState<String>("");

  const [tempRecipient, setTempRecipient] = React.useState<string>("")

  const toast = useToast()

  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const { isOpen, onOpen, onClose } = useDisclosure()



  const ctx = useContext(IkigaiContext)
  const g = ctx!.google;


  const handleRecipientInput = (e: any) => {
    console.log(e)
    const recipient = e.target.value
    let recipientsList = to
    setTempRecipient(recipient)
    if (recipient.includes(";")) {
      recipientsList.push(recipient.substring(0, recipient.length-1) )
      setTempRecipient("")
      setTo(recipientsList)
    }
  }

  const handleEnterPressed = (e: any) => {
    if (e.key === "Enter") {
      let recipientsList = to
      recipientsList.push(tempRecipient)
      setTempRecipient("")
      setTo(recipientsList)
    }
  }


  const renderRecipientTags = () => {
    return <HStack>{to.map((item) => <Badge>{item}</Badge>)}</HStack>
  }


  const handleEmailContentChange = (e: any) => {
    let inputValue = e.target.value
    setEmailContent(inputValue)
  }

  const onClickSendMail = () => {
    const rawMail = generateRawMimeMessage(from, to, subject, emailContent)
    g.sendEmail(rawMail).then((res) => {
      toast({
        position: 'top-right',
        title: 'Email Sent Successfully.',
        // description: "We've created your account for you.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      onClose()
    }).catch((err) => {
      toast({
        position: 'top-right',
        title: 'Error Email not Sent',
        description: err.toString(),
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    })
  }

  return (
    <>
      {/*<Button colorScheme='brand' type={"reset"} onClick={onOpen}>*/}
      {/*  Reply*/}
      {/*</Button>*/}
      <props.OpenButton onClick={onOpen}/>
      <Drawer
        placement={"left"}
        onClose={onClose}
        isOpen={isOpen}
        size={["full", "full", "full", "xl", "xl"]}
        >
        <DrawerOverlay />
        <DrawerContent height={"100%"}>
          <DrawerCloseButton />
          <DrawerHeader>{props.email?.subject}</DrawerHeader>
          <DrawerBody>
            <FormControl>
              <FormLabel>From:</FormLabel>
              <Select>
                <option value='option1'>{ikigaiUser.identity}</option>
              </Select>
              <FormLabel>To:</FormLabel>
              {renderRecipientTags()}<Input value={tempRecipient} type='email' onKeyUp={handleEnterPressed} onChange={handleRecipientInput}/>
              <FormLabel>Subject:</FormLabel>
              <Input type='subject' value={subject} onChange={(e) => setSubject(e.target.value)} />
            </FormControl>
            <Flex mt={"6"} height={"100%"} direction={"column"}>
              <Textarea
                value={emailContent}
                onChange={handleEmailContentChange}
                height={"65%"}
                // resize={"resize"}
                // size={"lg"}
              />
              <HStack mt={"6"}>
                <Button colorScheme='brand' type={"submit"} onClick={onClickSendMail}>Send</Button>
                <Button type={"reset"}>Save Draft</Button>
                <Button type={"reset"}>Cancel</Button>
              </HStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default ComposeEmailDrawer;
