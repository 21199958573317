import React from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  VStack,
  Flex,
  Spacer,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td,
  useBreakpointValue,
  Stack,
} from '@chakra-ui/react'
import ComposeEmailDrawer from "./ComposeEmailDrawer";
import {
  RadioButton,
  RadioButtonGroup
} from "../RadioButtonGroup";
type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  email?: Ikigai.emailCard;
}

const EmailDrawer = (props: Props) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  // const [label, setLabel] = React.useState("UNASSIGNED");


  const getIkigaiLabel = (): string => {
    if (props.isOpen) {
      const labels = props.email?.labels
      for (let i = 0; i < labels!.length; i++) {
        console.log(i)
        if (labels![i].startsWith("IKIGAI")) {
          return labels![i];
        }
      }
    }

    return "UNASSIGNED";
  }

  let label: string = getIkigaiLabel();


  return (
    <>
      <Drawer
        onClose={props.onClose}
        isOpen={props.isOpen}
        size={["full", "full", "full", "xl", "xl"]}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{props.email?.subject}</DrawerHeader>
          <DrawerBody>

            <VStack align={"left"}>
              <TableContainer>
                <Table variant={"unstyled"} size={"sm"}>
                  <Tbody>
                    <Tr>
                      <Td><b>From:</b></Td>
                      <Td>{props.email?.from}</Td>
                    </Tr>
                    <Tr>
                      <Td><b>To:</b></Td>
                      <Td>{props.email?.to}</Td>
                    </Tr>
                    <Tr>
                      <Td><b>Date:</b></Td>
                      <Td>{props.email?.date}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>

              <Stack direction={['column', 'row']} spacing='16px'>
                <RadioButtonGroup defaultValue={label} size={"sm"}>
                  <RadioButton value="UNASSIGNED">Unassigned</RadioButton>
                  <RadioButton value="IKIGAI/TODO">To Do</RadioButton>
                  <RadioButton value="IKIGAI/IN_PROGRESS">In Progress</RadioButton>
                  <RadioButton value="IKIGAI/DONE">Done</RadioButton>
                </RadioButtonGroup>
                <Spacer/>
                <ComposeEmailDrawer
                  email={props.email}
                  OpenButton={(props) => <Button colorScheme='brand' type={"reset"} {...props}>Reply</Button>}
                />
              </Stack>

            </VStack>
            <iframe
              srcDoc={props.email?.body}
              style={{border: "none"}}
              width="100%"
              height="100%"
              scrolling="no"
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default EmailDrawer;
