import axios from 'axios';
import {Buffer} from 'buffer';

export class google {

  readonly serviceEndPoint: string = "https://gmail.googleapis.com";
  private headers: { Authorization: string };
  private labels: Array<Gmail.Label> = [];

  constructor(googleAccessToken: string) {
    this.headers = {
      'Authorization': `Bearer ${googleAccessToken}`
    }

    this.listGmailLabels().then((labels) => {
      this.labels = labels;
    });
  }

  async Get(url: string, params: any) {
    const { data } = await axios.get(this.serviceEndPoint + url, {
      headers: this.headers,
      params: params,
    });
    return data;
  }

  async Post(url: string, postData: any) {
    const config = {
      headers: this.headers,
    }
    const {data} = await axios.post(this.serviceEndPoint + url, postData, config);
    return data
  }

  async listGmailLabels() {
    return this.Get("/gmail/v1/users/me/labels", {}).then((data) => {
      return data.labels
    });
  }

  getLabelId(labelName: string) {
    const label = this.labels.find((label) => {
      return label.name === labelName;
    });
    return label?.id;
  }

  getLabelName(labelId: string) {
    const label = this.labels.find((label) => {
      return label.id === labelId;
    });
    return label?.name;
  }

  async getKanbanEmails(): Promise<Ikigai.KanbanMails> {
    const todo = await this.listGmailMessages("label:IKIGAI/TODO", 100);
    const inProgress = await this.listGmailMessages("label:IKIGAI/IN_PROGRESS", 100);
    const done = await this.listGmailMessages("label:IKIGAI/DONE", 100);
    const unassigned = await this.listGmailMessages("-label:IKIGAI/TODO -label:IKIGAI/IN_PROGRESS -label:IKIGAI/DONE -label:Sent", 20);

    return {
      unassigned: unassigned,
      inProgress: inProgress,
      done: done,
      todo: todo,
    };
  }

  async listGmailMessages(query: string, maxResults: number): Promise<Ikigai.emailCard[]> {

    const params = {
      "maxResults": maxResults,
      "q": query,
    }
    return this.Get("/gmail/v1/users/me/messages", params).then(async (data) => {
      // console.log(data.messages);
      let emails: Ikigai.emailCard[] = [];
      if (data.messages) {
        for (let i = 0; i < data.messages.length; i++) {
          const msg = await this.getGmailMessage(data.messages[i].id)
          emails.push(msg);
        }
      }
      return emails;
    });
  }

  async getGmailMessage(messageId: string): Promise<Ikigai.emailCard> {
    const params = {
      "format": "full",
    }

    const getHeader = (headers: any, name: string) => {
      let res = ""
      headers.forEach((header: any) => {
        // console.log(header)
        if (header.name === name) {
          res = header.value;
       }
     });
     return res;
    }

    const data = await this.Get(`/gmail/v1/users/me/messages/${messageId}`, params)
    let body = "";

    if (data.payload?.parts) {
      data.payload.parts.forEach((part: any) => {
        if (part.mimeType === "text/html") {
          const buff = Buffer.from(part.body.data, 'base64');
          body = buff.toString('utf-8');
        }
      })
    } else {
      const buff = Buffer.from(data.payload.body.data, 'base64');
      body = buff.toString('utf-8');
    }

    const labels: Array<string> = [];
    data.labelIds.forEach((labelId: string) => {
      const labelName = this.getLabelName(labelId)
      if (labelName) {
        labels.push(labelName);
      }
    });

    // console.log(data);
    const email: Ikigai.emailCard = {
      id: data.id,
      subject: getHeader(data.payload.headers, "Subject"),
      snippet: data.snippet,
      from: getHeader(data.payload.headers, "From"),
      to: getHeader(data.payload.headers, "To"),
      date: getHeader(data.payload.headers, "Date"),
      labels: labels,
      body: body,
    }

    return email;
  }


  async setMessageLane(messageId: string, oldLane: string, newLane: string) {
    const params ={
      "addLabelIds": [this.getLabelId(newLane)],
      "removeLabelIds": [this.getLabelId(oldLane)],
    }
    const url = `/gmail/v1/users/me/messages/${messageId}/modify`
    return this.Post(url, params)
      .then((data) => {
        return data
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async addLabelToMessage(messageId: string, labelId: string) {
    const params = {
      "addLabelIds": [labelId],
    }
    return this.Post(`/gmail/v1/users/me/messages/${messageId}/modify`, params);
  }

  async removeLabelToMessage(messageId: string, labelId: string) {
    const params = {
      "removeLabelIds": [labelId],
    }
    return this.Post(`/gmail/v1/users/me/messages/${messageId}/modify`, params);
  }

  async markMessageAsRead(messageId: string) {
    this.removeLabelToMessage(messageId, "UNREAD");
  }

  async listGmailThreads() {

    const params = {
      "maxResults": 10,
    }
    return this.Get("/gmail/v1/users/me/threads", params).then(async (data) => {
      // console.log(data);
      let threads = [];
      for (let i = 0; i < data.threads.length; i++) {
        const msg = await this.getGmailThread(data.threads[i].id)
        threads.push(msg);
      }

    });
  }

  async getGmailThread(threadId: string) {
    const params = {
      "format": "full",
    }
    return this.Get(`/gmail/v1/users/me/threads/${threadId}`, params).then((data) => {
      return data
    });
  }

  async getProfile() {
    return this.Get("/gmail/v1/users/me/profile", {}).then((data) => {
      return data
    });
  }

  async sendEmail(rawEmail: string) {
    //Encode base64
    const rawEmailB64 = Buffer.from(rawEmail).toString('base64')
    // console.log(rawEmail)
    // console.log(rawEmailB64)

    const params = {
      "raw": rawEmailB64,
    }
    return this.Post("/gmail/v1/users/me/messages/send", params).then((data) => {
      return data
    })
  }

}




